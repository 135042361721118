.description {
    text-align: left;
}

.possibilities {
    text-align: left;
    color: gray;
}

.result {
    font-size: 36px;
    text-transform: uppercase;
    text-align: left;
    overflow-wrap: break-word;
}

.resultEffect {
    font-size: 20px;
    text-align: left;
    padding-top: 15px;
}

.resultDescription {
    font-size: 16px;
    text-align: left;
    color: gray;
    padding-top: 20px;
}

@media all and (min-width: 350px) {
    .result {
        font-size: 42px;
    }
}

@media all and (min-width: 500px) {
    .result {
        font-size: 50px;
    }
}

.actions {
    margin-top: 50px;
    text-align: left;
    margin-bottom: 50px;
    button {
        margin-right: 20px;
    }
}