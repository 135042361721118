.newWrapper {
    padding: 10px;
    color: var(--color-black);
    background-color: #f6d55c;
    margin-bottom: 20px;
    font-weight: 700;
    text-align: center;
    font-style: italic;
    border-radius: 0.375rem;
}
.newWrapper:hover {
    cursor: pointer;
}

.homemenu {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    .menuItem {
        height: 150px;
        width: 500px;
        position: relative;
        .menuLabel {
            position: absolute;
            bottom: 0;
            padding: 20px;
            background-color: var(--color-black);
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            text-align: left;
        }
    }
    .menuItem:hover {
        cursor: pointer;
    }
}

@media all and (max-width: 600px) {
    .homemenu {
        .menuItem {
            height: 100px;
            .menuLabel {
                font-size: 10px;
                padding: 10px;
            }
        }
    }
}