@use "../../colors.scss";

.tables {
    text-align: left;
    .tableList {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .tableItem {
            height: 200px;
            width: 380px;
            position: relative;
            .tableName {
                position: absolute;
                bottom: 0;
                padding: 20px;
                background-color: var(--color-black);
                font-size: 20px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        .tableItem:hover {
            cursor: pointer;
        }
    }
}

.tablePage {
    text-align: left;
    .headWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .textHead {
            max-width: 600px;
        }
        .imageHead {
            width: 100%;
            max-width: 512px;
        }
    }
    .tableDescription {
        margin-bottom: 20px;
    }
    .tableImage {
        height: 150px;
        margin-bottom: 20px;
    }
    table {
        width: 100%;
        border: 1px solid var(--color-white);
        margin-bottom: 100px;
        tr {
            border-bottom: 1px solid var(--color-white);
            td {
                padding: 10px;
                border-right: 1px solid var(--color-white);
            }
            td:first-of-type {
                text-align: center;
            }
            th {
                padding: 10px;
                border-right: 1px solid var(--color-white);
            }
        }
        tr.highlight {
            font-weight: bold;
            background-color: var(--color-white);
            color: var(--color-black);
        }
    }
    .buttonWrapper {
        background: var(--color-black);
        padding: 20px;
        position: fixed;
        bottom: 40px;
        right: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        box-shadow: 0 4px 10px -3px black;
    }
}