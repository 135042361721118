.maps {
    text-align: left;
    .mapListItem {
        padding: 25px 0;
        .mapName {
            font-size: 24px;
            text-transform: uppercase;
            button {
                float: right;
                margin-bottom: 10px;
            }
        }
        .imgWrapper {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background-color: black;
            margin-top: 20px;
            img {
                width: 100%;
                margin: auto;
            }
        }
    }
}