@use "../../colors.scss";

.header {
    color: var(--color-white);
    font-weight: 700;
    font-size: 32px;
    background-color: var(--color-black);
    height: 90px;
    box-shadow: 0 4px 10px -3px black;
    width: 100%;
    position: fixed;
    z-index: 1000;

    .inner {
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        margin: auto;
        .title {
            .center {
                img {
                    width: 147px;
                    height: 35px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
            flex-grow: 4;
        }

        .menu, .insta {
            color: gray;
            flex-grow: 1;
        }

        .insta svg:first-of-type {
            margin-right: 15px;
        }
    }
}

.offcanvas {
    .offcanvas-body {
        padding-left: 0;
        padding-right: 0;
        .menuItem {
            padding: 20px;
            font-size: 20px;
            display: block;
            width: 100%;
            text-decoration: none;
            color: var(--color-black);
        }
        .menuItem:hover {
            cursor: pointer;
            background-color: var(--color-black);
            color: var(--color-white);
        }
    }
}