.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    margin-top: 50px;
    box-sizing: border-box;
    height: 146px;
    .insta {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}