@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
  background-color: #333333;
  color: #E3E2E2;
  min-height: 100vh;
  position: relative;

  .content {
    max-width: 1280px;
    margin: auto;
    padding-top: 140px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 146px;
  }
}

@media all and (max-width: 600px) {
  .App {
      .content {
          padding-top: 110px;
      }
  }
}